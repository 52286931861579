body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h-100vh {
  height: 100vh !important;
}

.h-full {
  height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-0 { margin-left: 0 !important }
.mr-0 { margin-right: 0 !important }
.mt-0 { margin-top: 0 !important }
.mb-0 { margin-bottom: 0 !important }
.ml-1 { margin-left: 1rem !important }
.mr-1 { margin-right: 1rem !important }
.mt-1 { margin-top: 1rem !important }
.mb-1 { margin-bottom: 1rem !important }

.p-0 {
  padding: 0 !important;
}

.t-0 { top: 0 !important }
.b-0 { bottom: 0 !important }
.l-0 { left: 0 !important }
.r-0 { right: 0 !important }

.hidden {
  display: none !important;
}

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-center {
  align-items: center !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.break-word {
  overflow-wrap: break-word !important;
}

.overflow-visible {
  overflow: visible !important;
}
