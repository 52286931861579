.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

.App-header {
  display: flex;
  flex: 0 0 auto;
}

.App-content {
  display: flex;
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 0px 24px;
}

.authenticating {
  width: 100%;
  padding: 64px 0px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.not-logged-in {
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.logged-in {
  display: flex;
  justify-content: center;
  align-items: center;
}
